import React, { ChangeEvent, useRef } from "react";
interface DropAreaProps {
  onNewFile?: (file: File) => void;
  label?: string;
}

export const DropArea = ({ onNewFile, label }: DropAreaProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFile = (e: any) => {
    const input: FileList = e.files;
    onNewFile?.(input?.[0]);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    
  }

  const preventEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }
  return (
    <div
      className="flex justify-center items-center bg-white hover:bg-button hover:bg-opacity-15 border border-dashed border-neutral-3 group hover:border-button hover:border-opacity-35 rounded-2xl h-[83px] p-3 hover:cursor-pointer transition-all duration-200"
      onDrop={(e) => {
        preventEvents(e)
        handleFile(e.dataTransfer)
      }}
      data-testid="drop-area"
      onDragOver={(e) => preventEvents(e)}
      onClick={() => fileInputRef?.current?.click()}
    >
      <div className="flex flex-row justify-center items-center">
        <img src={"/img/icons/import.svg"} alt="edit" className="h-[37px] w-[37px] bg-neutral-3 group-hover:bg-button group-hover:bg-opacity-35 border border-neutral-5 group-hover:border-button group-hover:border-opacity-35 p-[7px] rounded-full transition-all duration-200"/>
        <p className="text-[14px] ml-8 font-ArialRegular">
          {label || "Arrastra el archivo o selecciona desde tus documentos"}
        </p>
        <input
          className="hidden"
          type="file"
          max={1}
          ref={fileInputRef}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFile(e.target) }
          data-testid="file-input"
        />
      </div>
    </div>
  );
};

