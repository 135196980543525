import { URL_API } from "../../utils/constants";
import { ID_STATUS, IPendingTransfers, TypeStatus } from "../../interfaces/pendingTranfers";
import { ConsumeService } from "../ConsumeService";
import { IApproveTransfer, ITransfer } from "../../interfaces/transfersInterface";
import { GeneralResponse } from "../../interfaces/utilsInterface";

export const TransfersService = {

  async getPendingTransfers (status: TypeStatus): Promise<IPendingTransfers[]> {
    try {
      const idStatus = ID_STATUS[status];
      const url = `${URL_API.TRANSFERS_PENDING.BASE}?status=${idStatus}`;
      const { data } = await ConsumeService.get(url);
      return data.data ?? [];

    } catch (error) {
      return []
    }
  },

  async approvePendingTransfers (body: IApproveTransfer, otp: string): Promise<any> {
    try {
      const { data } = await ConsumeService.post(URL_API.TRANSFERS_PENDING.APPROVE, body, { headers: { 'Kubit-OTP': `OTP ${otp}` } } );
      return {
        status: data.status,
        code: data.code,
      };

    } catch (error: any) {
      return {
        status: error.message,
        code: error.status,
        message: error.response?.data?.message || error.message
      };
    }
  },

  /**
   * Permite realizar una transfetrencia entre cuentas en la plataforma Kubit.
   * Se requiere un apikey, un token y un OTP válidos para realizar la petición.
   */
  async sendTransfer (dataTransfer: ITransfer, otp: string): Promise<GeneralResponse> {
    try {
      const { data } = await ConsumeService.post(URL_API.TRANSFERS_SEND, dataTransfer, { headers: { 'Kubit-OTP': `OTP ${otp}` } } );
      return {
        status: data.status,
        code: data.code,
      };

    } catch (error: any) {
      return {
        status: error.message,
        code: 500,
        message: error?.message?.message || error?.data?.message || '',
      };
    }
  },

  /**
   * Permite realizar una transfetrencia masiva
   * Se un OTP válido para realizar la petición.
   */
  async sendTransferMassive (body: FormData, otp: string): Promise<GeneralResponse> {
    try {
      const { data } = await ConsumeService.post(URL_API.MASS_TRANSFERS_SEND, body, { headers: { 
        'Kubit-OTP': `OTP ${otp}`,
        'Content-Type': 'multipart/form-data',
      }, timeout: 60000 } );
      return {
        status: data.status,
        code: data.code,
      };

    } catch (error: any) {
      return {
        status: error.message,
        code: error.status,
        message: error?.message?.message || error?.data?.message || '',
      };
    }
  }
}