
export const security = ({fill, stroke, className}) => (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/security-safe">
        <g id="security-safe">
        <path id="Vector" d="M7.99988 15.1669C7.83988 15.1669 7.67988 15.1469 7.52655 15.1002C4.06655 14.1469 1.55988 10.9135 1.55988 7.40686V4.48018C1.55988 3.73351 2.09988 2.92688 2.79321 2.64021L6.50655 1.1202C7.47322 0.726862 8.53321 0.726862 9.49321 1.1202L13.2065 2.64021C13.8999 2.92688 14.4399 3.73351 14.4399 4.48018V7.40686C14.4399 10.9069 11.9265 14.1402 8.47321 15.1002C8.31987 15.1469 8.15988 15.1669 7.99988 15.1669ZM7.99988 1.83353C7.61988 1.83353 7.24654 1.90688 6.88654 2.05354L3.17322 3.57352C2.85322 3.70686 2.55988 4.14019 2.55988 4.48685V7.41353C2.55988 10.4735 4.75988 13.3002 7.79321 14.1402C7.92655 14.1802 8.07321 14.1802 8.20654 14.1402C11.2399 13.3002 13.4399 10.4735 13.4399 7.41353V4.48685C13.4399 4.14019 13.1465 3.70686 12.8265 3.57352L9.11322 2.05354C8.75322 1.90688 8.37988 1.83353 7.99988 1.83353Z" fill={fill}/>
        <path id="Vector_2" d="M8.00002 8.83317C6.98669 8.83317 6.16669 8.01317 6.16669 6.99984C6.16669 5.9865 6.98669 5.1665 8.00002 5.1665C9.01335 5.1665 9.83335 5.9865 9.83335 6.99984C9.83335 8.01317 9.01335 8.83317 8.00002 8.83317ZM8.00002 6.1665C7.54002 6.1665 7.16669 6.53984 7.16669 6.99984C7.16669 7.45984 7.54002 7.83317 8.00002 7.83317C8.46002 7.83317 8.83335 7.45984 8.83335 6.99984C8.83335 6.53984 8.46002 6.1665 8.00002 6.1665Z" fill={fill}/>
        <path id="Vector_3" d="M8 10.8335C7.72667 10.8335 7.5 10.6068 7.5 10.3335V8.3335C7.5 8.06016 7.72667 7.8335 8 7.8335C8.27333 7.8335 8.5 8.06016 8.5 8.3335V10.3335C8.5 10.6068 8.27333 10.8335 8 10.8335Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
