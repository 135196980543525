
export const logout = ({fill, stroke, className}) => (
    <svg className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/logout">
        <g id="logout">
        <path id="Vector" d="M9.3999 7.56C9.7099 3.96 11.5599 2.49 15.6099 2.49H15.7399C20.2099 2.49 21.9999 4.28 21.9999 8.75V15.27C21.9999 19.74 20.2099 21.53 15.7399 21.53H15.6099C11.5899 21.53 9.7399 20.08 9.4099 16.54" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M15.5001 12H4.12012" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M6.35 8.65L3 12L6.35 15.35" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    