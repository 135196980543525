
export const schedule = ({fill, stroke, className}) => (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/calendar-edit">
        <g id="calendar-edit">
        <path id="Vector" d="M8.66663 6.22949C8.22246 6.22949 7.85413 5.86116 7.85413 5.41699V2.16699C7.85413 1.72283 8.22246 1.35449 8.66663 1.35449C9.11079 1.35449 9.47913 1.72283 9.47913 2.16699V5.41699C9.47913 5.86116 9.11079 6.22949 8.66663 6.22949Z" fill={fill}/>
        <path id="Vector_2" d="M17.3334 6.22949C16.8892 6.22949 16.5209 5.86116 16.5209 5.41699V2.16699C16.5209 1.72283 16.8892 1.35449 17.3334 1.35449C17.7775 1.35449 18.1459 1.72283 18.1459 2.16699V5.41699C18.1459 5.86116 17.7775 6.22949 17.3334 6.22949Z" fill={fill}/>
        <path id="Vector_3" d="M9.20833 15.7081C9.0675 15.7081 8.92666 15.6757 8.79666 15.6215C8.65583 15.5673 8.54751 15.4915 8.43918 15.394C8.24418 15.1881 8.125 14.9065 8.125 14.6248C8.125 14.3431 8.24418 14.0615 8.43918 13.8556C8.54751 13.7581 8.66666 13.6823 8.79666 13.6281C9.05666 13.5198 9.36001 13.5198 9.62001 13.6281C9.75001 13.6823 9.86916 13.7581 9.97749 13.8556C10.0208 13.9098 10.075 13.964 10.1075 14.0181C10.1508 14.0831 10.1833 14.1481 10.205 14.2131C10.2375 14.2781 10.2592 14.3431 10.27 14.4081C10.2808 14.484 10.2917 14.5598 10.2917 14.6248C10.2917 14.9065 10.1725 15.1881 9.97749 15.394C9.86916 15.4915 9.75001 15.5673 9.62001 15.6215C9.49001 15.6757 9.34917 15.7081 9.20833 15.7081Z" fill={fill}/>
        <path id="Vector_4" d="M13 15.7088C12.8591 15.7088 12.7183 15.6763 12.5883 15.6221C12.4475 15.568 12.3391 15.4921 12.2308 15.3946C12.0358 15.1888 11.9166 14.9071 11.9166 14.6254C11.9166 14.5604 11.9274 14.4846 11.9383 14.4088C11.9491 14.3438 11.9708 14.2788 12.0033 14.2138C12.0249 14.1488 12.0575 14.0838 12.1008 14.0188C12.1441 13.9646 12.1875 13.9105 12.2308 13.8563C12.6316 13.4555 13.3574 13.4555 13.7691 13.8563C13.8124 13.9105 13.8558 13.9646 13.8991 14.0188C13.9424 14.0838 13.975 14.1488 13.9966 14.2138C14.0291 14.2788 14.0508 14.3438 14.0616 14.4088C14.0725 14.4846 14.0833 14.5604 14.0833 14.6254C14.0833 14.9071 13.9641 15.1888 13.7691 15.3946C13.5633 15.5896 13.2925 15.7088 13 15.7088Z" fill={fill}/>
        <path id="Vector_5" d="M9.20833 19.5001C9.0675 19.5001 8.92666 19.4676 8.79666 19.4135C8.66666 19.3593 8.54751 19.2835 8.43918 19.186C8.24418 18.9801 8.125 18.6985 8.125 18.4168C8.125 18.3518 8.13582 18.276 8.14665 18.211C8.15749 18.1351 8.17915 18.0701 8.21165 18.0051C8.23332 17.9401 8.26585 17.8751 8.30918 17.8101C8.34168 17.756 8.39584 17.7018 8.43918 17.6476C8.54751 17.5501 8.66666 17.4743 8.79666 17.4201C9.05666 17.3118 9.36001 17.3118 9.62001 17.4201C9.75001 17.4743 9.86916 17.5501 9.97749 17.6476C10.0208 17.7018 10.075 17.756 10.1075 17.8101C10.1508 17.8751 10.1833 17.9401 10.205 18.0051C10.2375 18.0701 10.2592 18.1351 10.27 18.211C10.2808 18.276 10.2917 18.3518 10.2917 18.4168C10.2917 18.6985 10.1725 18.9801 9.97749 19.186C9.86916 19.2835 9.75001 19.3593 9.62001 19.4135C9.49001 19.4676 9.34917 19.5001 9.20833 19.5001Z" fill={fill}/>
        <path id="Vector_6" d="M22.2083 10.6602H3.79163C3.34746 10.6602 2.97913 10.2918 2.97913 9.84766C2.97913 9.40349 3.34746 9.03516 3.79163 9.03516H22.2083C22.6525 9.03516 23.0208 9.40349 23.0208 9.84766C23.0208 10.2918 22.6525 10.6602 22.2083 10.6602Z" fill={fill}/>
        <path id="Vector_7" d="M17.1384 24.6787C16.7268 24.6787 16.3368 24.5271 16.0551 24.2454C15.7193 23.9096 15.5676 23.4221 15.6434 22.9129L15.8493 21.4504C15.9034 21.0712 16.1309 20.6162 16.4018 20.3454L20.2368 16.5104C20.7568 15.9904 21.2659 15.7196 21.8184 15.6654C22.5009 15.6004 23.1618 15.8821 23.7901 16.5104C24.4509 17.1712 25.3393 18.5146 23.7901 20.0637L19.9551 23.8987C19.6843 24.1696 19.2293 24.3971 18.8501 24.4512L17.3876 24.6571C17.3009 24.6679 17.2251 24.6787 17.1384 24.6787ZM22.0026 17.2796C21.9918 17.2796 21.9809 17.2796 21.9701 17.2796C21.8184 17.2904 21.6126 17.4312 21.3851 17.6587L17.5501 21.4937C17.5176 21.5262 17.4634 21.6346 17.4634 21.6779L17.2684 23.0321L18.6226 22.8371C18.6659 22.8262 18.7742 22.7721 18.8067 22.7396L22.6418 18.9046C23.1184 18.4279 23.1834 18.1896 22.6418 17.6479C22.4684 17.4854 22.2193 17.2796 22.0026 17.2796Z" fill={fill}/>
        <path id="Vector_8" d="M22.6635 20.8545C22.5876 20.8545 22.5118 20.8437 22.4468 20.822C21.0168 20.4212 19.8793 19.2837 19.4785 17.8537C19.3593 17.4203 19.6085 16.9762 20.0418 16.8462C20.4751 16.727 20.9193 16.9762 21.0493 17.4095C21.2985 18.2978 22.0026 19.002 22.891 19.2512C23.3243 19.3703 23.5735 19.8253 23.4543 20.2587C23.346 20.6162 23.021 20.8545 22.6635 20.8545Z" fill={fill}/>
        <path id="Vector_9" d="M13 24.6462H8.66667C4.7125 24.6462 2.4375 22.3712 2.4375 18.417V9.20866C2.4375 5.25449 4.7125 2.97949 8.66667 2.97949H17.3333C21.2875 2.97949 23.5625 5.25449 23.5625 9.20866V13.0003C23.5625 13.4445 23.1942 13.8128 22.75 13.8128C22.3058 13.8128 21.9375 13.4445 21.9375 13.0003V9.20866C21.9375 6.11033 20.4317 4.60449 17.3333 4.60449H8.66667C5.56833 4.60449 4.0625 6.11033 4.0625 9.20866V18.417C4.0625 21.5153 5.56833 23.0212 8.66667 23.0212H13C13.4442 23.0212 13.8125 23.3895 13.8125 23.8337C13.8125 24.2778 13.4442 24.6462 13 24.6462Z" fill={fill}/>
        </g>
        </g>
    </svg>
)