
export const addCustomerInactive = ({fill, stroke, className}) => (
    <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/profile-tick">
        <g id="profile-tick">
        <path id="Vector" d="M14.9399 19.0503L16.4599 20.5703L19.4999 17.5303" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M12.6601 10.87C12.5601 10.86 12.4401 10.86 12.3301 10.87C9.9501 10.79 8.0601 8.84 8.0601 6.44C8.0501 3.99 10.0401 2 12.4901 2C14.9401 2 16.9301 3.99 16.9301 6.44C16.9301 8.84 15.0301 10.79 12.6601 10.87Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M12.49 21.8097C10.67 21.8097 8.86004 21.3497 7.48004 20.4297C5.06004 18.8097 5.06004 16.1697 7.48004 14.5597C10.23 12.7197 14.74 12.7197 17.49 14.5597" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    