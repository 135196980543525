
export const calendar = ({fill, stroke, className}) => (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/calendar-add">
        <g id="calendar-add">
        <path id="Vector" d="M8.66666 6.22949C8.22249 6.22949 7.85416 5.86116 7.85416 5.41699V2.16699C7.85416 1.72283 8.22249 1.35449 8.66666 1.35449C9.11082 1.35449 9.47916 1.72283 9.47916 2.16699V5.41699C9.47916 5.86116 9.11082 6.22949 8.66666 6.22949Z" fill={fill}/>
        <path id="Vector_2" d="M17.3333 6.22949C16.8892 6.22949 16.5208 5.86116 16.5208 5.41699V2.16699C16.5208 1.72283 16.8892 1.35449 17.3333 1.35449C17.7775 1.35449 18.1458 1.72283 18.1458 2.16699V5.41699C18.1458 5.86116 17.7775 6.22949 17.3333 6.22949Z" fill={fill}/>
        <path id="Vector_3" d="M9.20833 15.7085C9.0675 15.7085 8.92666 15.6869 8.79666 15.6219C8.66666 15.5677 8.54749 15.4918 8.43916 15.3943C8.24416 15.1885 8.125 14.9068 8.125 14.6252C8.125 14.4843 8.1575 14.3435 8.21167 14.2135C8.26584 14.0835 8.34166 13.9644 8.43916 13.856C8.54749 13.7585 8.66666 13.6827 8.79666 13.6285C9.18666 13.466 9.67417 13.5527 9.97751 13.856C10.075 13.9644 10.1508 14.0835 10.205 14.2135C10.2592 14.3435 10.2917 14.4843 10.2917 14.6252C10.2917 14.9068 10.1725 15.1885 9.97751 15.3943C9.77167 15.5893 9.49 15.7085 9.20833 15.7085Z" fill={fill}/>
        <path id="Vector_4" d="M13 15.7084C12.7183 15.7084 12.4366 15.5893 12.2308 15.3943C12.1333 15.2859 12.0575 15.1668 12.0033 15.0368C11.9492 14.9068 11.9167 14.7659 11.9167 14.6251C11.9167 14.3434 12.0358 14.0618 12.2308 13.8559C12.5341 13.5526 13.0108 13.4551 13.4117 13.6284C13.5417 13.6826 13.6608 13.7584 13.7692 13.8559C13.9642 14.0618 14.0833 14.3434 14.0833 14.6251C14.0833 14.7659 14.0617 14.9068 13.9967 15.0368C13.9425 15.1668 13.8667 15.2859 13.7692 15.3943C13.6608 15.4918 13.5417 15.5676 13.4117 15.6218C13.2817 15.6868 13.1408 15.7084 13 15.7084Z" fill={fill}/>
        <path id="Vector_5" d="M9.20833 19.5004C9.0675 19.5004 8.92666 19.4679 8.79666 19.4138C8.66666 19.3596 8.54749 19.2838 8.43916 19.1863C8.24416 18.9804 8.125 18.7096 8.125 18.4171C8.125 18.2763 8.1575 18.1354 8.21167 18.0054C8.26584 17.8646 8.34166 17.7563 8.43916 17.6479C8.54749 17.5504 8.66666 17.4746 8.79666 17.4204C9.18666 17.2471 9.67417 17.3446 9.97751 17.6479C10.075 17.7563 10.1508 17.8646 10.205 18.0054C10.2592 18.1354 10.2917 18.2763 10.2917 18.4171C10.2917 18.7096 10.1725 18.9804 9.97751 19.1863C9.77167 19.3813 9.49 19.5004 9.20833 19.5004Z" fill={fill}/>
        <path id="Vector_6" d="M22.2083 10.6602H3.79166C3.34749 10.6602 2.97916 10.2918 2.97916 9.84766C2.97916 9.40349 3.34749 9.03516 3.79166 9.03516H22.2083C22.6525 9.03516 23.0208 9.40349 23.0208 9.84766C23.0208 10.2918 22.6525 10.6602 22.2083 10.6602Z" fill={fill}/>
        <path id="Vector_7" d="M19.5 25.7292C16.6617 25.7292 14.3542 23.4217 14.3542 20.5833C14.3542 17.745 16.6617 15.4375 19.5 15.4375C22.3383 15.4375 24.6458 17.745 24.6458 20.5833C24.6458 23.4217 22.3383 25.7292 19.5 25.7292ZM19.5 17.0625C17.5608 17.0625 15.9792 18.6442 15.9792 20.5833C15.9792 22.5225 17.5608 24.1042 19.5 24.1042C21.4392 24.1042 23.0208 22.5225 23.0208 20.5833C23.0208 18.6442 21.4392 17.0625 19.5 17.0625Z" fill={fill}/>
        <path id="Vector_8" d="M21.1142 21.4502H17.875C17.4308 21.4502 17.0625 21.0819 17.0625 20.6377C17.0625 20.1935 17.4308 19.8252 17.875 19.8252H21.1142C21.5583 19.8252 21.9267 20.1935 21.9267 20.6377C21.9267 21.0819 21.5692 21.4502 21.1142 21.4502Z" fill={fill}/>
        <path id="Vector_9" d="M19.5 23.1073C19.0558 23.1073 18.6875 22.739 18.6875 22.2948V19.0557C18.6875 18.6115 19.0558 18.2432 19.5 18.2432C19.9442 18.2432 20.3125 18.6115 20.3125 19.0557V22.2948C20.3125 22.739 19.9442 23.1073 19.5 23.1073Z" fill={fill}/>
        <path id="Vector_10" d="M16.6508 24.6462H8.66667C4.7125 24.6462 2.4375 22.3712 2.4375 18.417V9.20866C2.4375 5.25449 4.7125 2.97949 8.66667 2.97949H17.3333C21.2875 2.97949 23.5625 5.25449 23.5625 9.20866V17.7236C23.5625 18.0595 23.3567 18.3628 23.0317 18.482C22.7175 18.6012 22.36 18.5145 22.1325 18.2545C21.4608 17.4962 20.4967 17.0628 19.4892 17.0628C17.55 17.0628 15.9683 18.6445 15.9683 20.5837C15.9683 21.2228 16.1417 21.8512 16.4775 22.3928C16.6617 22.7178 16.9 22.9887 17.16 23.2162C17.42 23.4328 17.5175 23.7903 17.3983 24.1153C17.3008 24.4295 16.9975 24.6462 16.6508 24.6462ZM8.66667 4.60449C5.56833 4.60449 4.0625 6.11033 4.0625 9.20866V18.417C4.0625 21.5153 5.56833 23.0212 8.66667 23.0212H14.9717C14.5708 22.2845 14.3542 21.4503 14.3542 20.5837C14.3542 17.7453 16.6617 15.4378 19.5 15.4378C20.3558 15.4378 21.2008 15.6545 21.9375 16.0553V9.20866C21.9375 6.11033 20.4317 4.60449 17.3333 4.60449H8.66667Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
    