import Icon from '../../assets/icon';
import './OptionCard.scss';

type OptionCardProps = {
    label: string,
    icon: string,
    disabled?: boolean
}

export const OptionCard = ({ label, icon, disabled }: OptionCardProps) => {
    return(<div className='option-card-container' onClick={() => console.log(label)}>
        <div data-testid="icon-market" className={`flex w-[66px] h-[65px] justify-center items-center rounded-full ${disabled ? 'bg-neutral-4 bg-opacity-10 border border-neutral-4 border-opacity-10' : 'bg-marketPlaceBg'} `}>
            <Icon iconName={icon} className={`${disabled ? 'stroke-neutral-4' : 'stroke-marketPlaceContrast stroke-2' }`} alt='icon' />
        </div>
        <p className={`${disabled ? 'text-neutral-4' : 'text-primary-2'} font-PoppinsSemiBold leading-normal text-[16px]`}>{label}</p>
    </div>);
};