import { LoginRequest, LoginResponse } from "../../interfaces/authInterface";
import { IUserProfile } from "../../interfaces/userProfileInterface";
import { URL_API } from "../../utils/constants";
import { ConsumeService } from "../ConsumeService";

export const AuthService = {

    async authUser (bearer: string): Promise<IUserProfile | null> {
        try {
            const { data } = await ConsumeService.get(URL_API.AUTH.AUTHUSER, { headers: { 'Authorization': `Bearer ${bearer}` } });
            return data.data ?? null;
    
        } catch (error) {
            return null
        }
    },

    async login ({ username, password, forced = false, pbk }: LoginRequest): Promise<LoginResponse> {

        try {
            const { data } = await ConsumeService.post(URL_API.AUTH.LOGIN, { username, password, forced, pbk });
            return {
                status: 'ok',
                code: 200,
                data: data.data,
            };
    
        } catch (error: any) {
            if (error.response?.data) {
                return {
                    status: error.response.status,
                    code: error.response.code || error.response.status,
                    data: error.response.data,
                }
            }
            return {
                status: error.message,
                code: 500,
                data: {token:''}
            };
        }
    },

    async logout () {
    
        try {
            await ConsumeService.post(URL_API.AUTH.LOGOUT, {});
        } catch (error: any) {
            console.log(error);
        }
    },

    async createOTP (): Promise<string | null> {
        try {
            const { data } = await ConsumeService.post(URL_API.AUTH.ENROLL, {});
            return data.data ?? null;
        } catch (error: any) {
            return null
        }
    },

    async valideteOTP (otp: string): Promise<boolean> {
        try {
            const { data } = await ConsumeService.post(URL_API.AUTH.VALIDATE, {}, { headers: { 'Kubit-OTP': `OTP ${otp}` } });
            return data.code === 200;
        } catch (error: any) {
            return false
        }
    },
  
    async recoverPassword (email: string): Promise<null> {
        try {
            await ConsumeService.post(`${URL_API.AUTH.RECOVER_PASSWORD}`,{email});
            return null;
    
        } catch (error) {
            return null
        }
    },
}