import { useEffect, useState } from 'react'
import { AuthService } from '../../../service';
import { Button, InputForm } from '../../../components';

type typeStatusOTP = 'loading' | 'valid' | 'invalid';

const renderErrorOTP = () => {
  return (
    <>
      <p className='text-[14px]'>Lo sentimos, tuvimos un problema para generar tu código.</p>
      <p className='text-[12px] mt-3'>Es posible que tengas uno previamente generado, solicita ayuda en <b>Reportar un problema</b> ó comunícate a <b><a href='mailto:contacto@livingrock.mx'>contacto@livingrock.mx</a></b></p>
    </>
  )
}

export const CreateOTP = () => {
    const [imgOTP, setImgOTP] = useState<string>('');
    const [errorOTP, setErrorOTP] = useState<boolean>(false);
    const [codeOTP, setCodeOTP] = useState<string>('');
    const [statusOTP, setStatusOTP] = useState<typeStatusOTP>();

    const generateOTP = async () => {
        const res = await AuthService.createOTP();
        if(res)
          setImgOTP(`data:image/png;base64,${res}`);
        else
          setErrorOTP(true);
    }

    const validOTP = async() => {
      const res = await AuthService.valideteOTP(codeOTP);

      setStatusOTP( res ? 'valid' : 'invalid');
    }

    const renderIconValid = () => {
      if(!statusOTP){
        return <></>;
      }

      const catStatus: { [key in typeStatusOTP]?: any } = {
        'valid': <img src={`/img/icons/check.svg`} alt='check' className='h-[40px] transition-all duration-200'/>,
        'invalid': <img src={`/img/icons/cross.svg`} alt='cross' className='h-[40px] transition-all duration-200'/>
      }

      return catStatus[statusOTP];
    }

    const renderTextValid = () => {
      if(!statusOTP){
        return <></>;
      }

      const catStatus: { [key in typeStatusOTP]?: any } = {
        'valid': <p className='ml-14 text-[12px] transition-all duration-200 mb-3 text-green-600'>El código es valido.</p>,
        'invalid': <p className='ml-14 text-[12px] transition-all duration-200 mb-3 text-red-600'>El código no es valido, verifica si ya expiro.</p>
      }

      return catStatus[statusOTP];
    }

    useEffect(() => {
      generateOTP();
    }, []);

    if(imgOTP === '' &&  !errorOTP)
      return <></>;

    
  return (
    <div className=' px-6'>
      <h3 className='font-SoraBold mb-3'>Genera tu código de OTP</h3>
      <div className='font-ArialRegular text-neutral-5'>
          
          {
            errorOTP ?
              renderErrorOTP()
            :
              <>
                <p className='text-[14px]'>Para terminar, necesitamos que realices las siguientes indicaciones:</p>
                <ul className='list-disc list-inside text-[14px] mt-3'>
                    <li>Descarga la aplicación <b>Google authenticator</b> desde tu dispositivo</li>
                    <li>Escanea el siguiente código desde la aplicación <b>Google authenticator</b></li>
                    <img className='w-[300px] h-[300px] m-auto' src={imgOTP} alt='code'/>
                    <li>Valida el código OTP que se genero en la aplicación <b>Google authenticator</b></li>
                    <div className='flex flex-row gap-4 items-center mx-3 mt-3'>
                      <div className='w-[40px]'>
                        {renderIconValid()}
                      </div>
                      <InputForm
                        placeholder='Ingresa código'
                        type='number'
                        maxLength={6}
                        onTextChange={(e: string) => setCodeOTP(e)}
                      />

                      <Button
                        label='Validar código'
                        type='primary'
                        size='medium'
                        disabled={!codeOTP}
                        onClick={() => validOTP()}
                      />
                    </div>
                    {renderTextValid()}
                </ul>
                <p className='text-[12px]'>*Si el código no funciona, solicita uno nuevo en <b>Reportar un problema</b> ó comunícate a <b><a href='mailto:contacto@livingrock.mx'>contacto@livingrock.mx</a></b></p>
              </>
          }
      </div>
    </div>
  )
}
