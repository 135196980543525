
export const warning = ({fill, stroke, className}) => (
    <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/danger">
        <g id="danger">
        <path id="Vector" d="M12 15.25C11.59 15.25 11.25 14.91 11.25 14.5V9.5C11.25 9.09 11.59 8.75 12 8.75C12.41 8.75 12.75 9.09 12.75 9.5V14.5C12.75 14.91 12.41 15.25 12 15.25Z" fill={fill}/>
        <path id="Vector_2" d="M12 18.5C11.94 18.5 11.87 18.49 11.8 18.48C11.74 18.47 11.68 18.45 11.62 18.42C11.56 18.4 11.5 18.37 11.44 18.33C11.39 18.29 11.34 18.25 11.29 18.21C11.11 18.02 11 17.76 11 17.5C11 17.24 11.11 16.98 11.29 16.79C11.34 16.75 11.39 16.71 11.44 16.67C11.5 16.63 11.56 16.6 11.62 16.58C11.68 16.55 11.74 16.53 11.8 16.52C11.93 16.49 12.07 16.49 12.19 16.52C12.26 16.53 12.32 16.55 12.38 16.58C12.44 16.6 12.5 16.63 12.56 16.67C12.61 16.71 12.66 16.75 12.71 16.79C12.89 16.98 13 17.24 13 17.5C13 17.76 12.89 18.02 12.71 18.21C12.66 18.25 12.61 18.29 12.56 18.33C12.5 18.37 12.44 18.4 12.38 18.42C12.32 18.45 12.26 18.47 12.19 18.48C12.13 18.49 12.06 18.5 12 18.5Z" fill={fill}/>
        <path id="Vector_3" d="M18.0605 22.66H5.94046C3.99046 22.66 2.50046 21.95 1.74046 20.67C0.990464 19.39 1.09046 17.74 2.04046 16.03L8.10046 5.13C9.10046 3.33 10.4805 2.34 12.0005 2.34C13.5205 2.34 14.9005 3.33 15.9005 5.13L21.9605 16.04C22.9105 17.75 23.0205 19.39 22.2605 20.68C21.5005 21.95 20.0105 22.66 18.0605 22.66ZM12.0005 3.84C11.0605 3.84 10.1405 4.56 9.41046 5.86L3.36046 16.77C2.68046 17.99 2.57046 19.11 3.04046 19.92C3.51046 20.73 4.55046 21.17 5.95046 21.17H18.0705C19.4705 21.17 20.5005 20.73 20.9805 19.92C21.4605 19.11 21.3405 18 20.6605 16.77L14.5905 5.86C13.8605 4.56 12.9405 3.84 12.0005 3.84Z" fill={fill}/>
        </g>
        </g>
    </svg>
)