
export const shop = ({fill, stroke, className}) => (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/shop">
        <g id="shop">
        <path id="Vector" d="M4.3877 14.9922V20.7918C4.3877 26.5914 6.7127 28.9164 12.5123 28.9164H19.4744C25.2739 28.9164 27.5989 26.5914 27.5989 20.7918V14.9922" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M16.0003 15.9987C18.364 15.9987 20.1078 14.0741 19.8753 11.7104L19.0228 3.08203H12.9907L12.1253 11.7104C11.8928 14.0741 13.6365 15.9987 16.0003 15.9987Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M24.1503 15.9987C26.7595 15.9987 28.6711 13.8804 28.4128 11.2841L28.0511 7.73203C27.5861 4.3737 26.2945 3.08203 22.9103 3.08203H18.9707L19.8749 12.1366C20.0945 14.2679 22.019 15.9987 24.1503 15.9987Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M7.78491 15.9987C9.91616 15.9987 11.8407 14.2679 12.0474 12.1366L12.3316 9.28203L12.9516 3.08203H9.01199C5.62783 3.08203 4.33616 4.3737 3.87116 7.73203L3.52241 11.2841C3.26408 13.8804 5.17574 15.9987 7.78491 15.9987Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M15.9997 22.457C13.8426 22.457 12.7705 23.5291 12.7705 25.6862V28.9154H19.2288V25.6862C19.2288 23.5291 18.1568 22.457 15.9997 22.457Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    