import { SpeiService } from "../service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setBanks } from "../redux/slices/transferSlice";
import { useEffect } from "react";

/**
 * Cusmtom hook para el manejo del catalogo de bancos
 */
export const useBanks = () => {
    const dispatch = useDispatch();
    const { banks } = useSelector(
        (state: RootState) => state.transfer
    );

    const getBanks = async () => {
        if (banks.length === 0) {
            const resp = await SpeiService.getBanks();
    
            dispatch(setBanks(resp));
        }
    }

    useEffect(() => {
        getBanks();
    }, [])


    return { banks };
};
