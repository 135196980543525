
export const searchNormal = ({fill, stroke, className}) => (
    <svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/search-normal">
        <g id="search-normal">
        <path id="Vector" d="M7.66668 14.4997C11.1645 14.4997 14 11.6641 14 8.16634C14 4.66854 11.1645 1.83301 7.66668 1.83301C4.16887 1.83301 1.33334 4.66854 1.33334 8.16634C1.33334 11.6641 4.16887 14.4997 7.66668 14.4997Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M14.6667 15.1663L13.3333 13.833" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    