import { ReactElement, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import { v4 } from 'uuid';

type btnType = 'ghost'| 'primary'| 'secondary'| 'success'| 'warning' | 'dotted' | 'tertiary' | 'primary-outline';
type btnSize = 'auto' | 'small' | 'medium' | 'large';

enum TYPE_BUTTON {
    GHOST       = 'ghost',
    PRIMARY     = 'primary',
    SECONDARY   = 'secondary',
    SUCCESS     = 'success',
    WARNING     = 'warning'
}

interface IButtonProps {
    icon?: ReactElement
    type: btnType,
    label: string,
    size?: btnSize,
    description?: string,
    outline?: boolean,
    iconLeft?: boolean,
    width?: string,
    className?: string,
    disabled?: boolean,
    name?: string,
    form?: string
    onClick?: () => void,
    textColor?: string
}

export const Button = ({
    type, 
    label, 
    icon, 
    size, 
    description, 
    width, 
    outline = false, 
    iconLeft = false, 
    onClick = undefined, 
    className = '', 
    disabled = false, 
    name = '', 
    form, 
    textColor
}: IButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorId, setAnchorId] = useState(v4());
    const getStyleBtn = (type: btnType): string => {

        const catTypes: {[key: string]: string} = {
          'ghost': "hover:bg-neutral-2",
          'primary': outline ? 'border-button border-solid border-2' : `bg-button ${textColor ?? 'text-buttonContrast'}`,
          'secondary': 'bg-neutral-3 text-black',
          'tertiary': 'bg-semantic-3 text-white',
          'success': 'bg-semantic-4 text-white',
          'warning': 'bg-semantic-2 text-white',
          'dotted': 'border-2 border-dotted border-semantic-3',
          'primary-outline': 'border-button border-solid border-2 text-button bg-button bg-opacity-10'
        }

        return catTypes[type] || '';
    }

    const getWidthBtn = (size?: btnSize): string => {

        const catSizes: {[key: string]: string} = {
            'auto': "w-auto",
            'small': "md:w-[156px] w-full",
            'medium': 'w-[264px]',
            'large': 'w-full',
        }

        return width ? `w-[${width}]` : (catSizes[size!] || 'w-full');
    }

    const checkTooltip = () => {

        if(onClick || form){
            setIsOpen(false);
            return
        }

        setIsOpen(true);
    }
    

  return (
    <>
        <Tooltip
            id='tooltip-account'
            className='tooltip'
            anchorSelect={`#anchor-button-${anchorId}`}
            isOpen={isOpen}
        >
            Función no disponible
        </Tooltip>
        
        <button
            onClick={onClick}
            id={`anchor-button-${anchorId}`}
            className={`flex justify-center items-center gap-3 h-[50px] rounded-full px-4 ${className} ${getStyleBtn(type)} ${getWidthBtn(size)} ${iconLeft ? 'flex-row-reverse' : ''}`}
            onMouseEnter={checkTooltip}
            onMouseLeave={() => setIsOpen(false)}
            disabled={disabled}
            name={name}
            form={form}
        >
            {
                description ?
                    <div className='flex flex-col text-left'>
                        <div className='font-ArialBold text-[16px] leading-[21.9px] text-black'>{label}</div>
                        <div className='text-[10px] md:text-[12px] leading-[15px] text-neutral-5'>{description}</div>
                    </div>
                :
                    <div className={`${type === TYPE_BUTTON.GHOST ? 'font-ArialRegular' : 'font-ArialBold'} text-base`}>{label}</div>
            }
            {icon}
        </button>
    </>
  )
}
