import './SearchInput.scss';
import classNames from 'classnames';

type SearchProps = {
    type: 'header' | 'form',
    placeholder: string,
    callback: Function,
}

export const SearchInput = ({ type, placeholder, callback }: SearchProps) => {

    const searchClass = classNames({
        'search-input-container': true,
        'form': type === 'form',
    });

    return(<div className={`${searchClass} border-neutral-4 border`}>
        <img src={`/img/icons/search.svg`} alt='search' />
        <input placeholder={placeholder} onChange={e => callback(e.target.value)}/>
    </div>
    );
};
