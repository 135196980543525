// eslint-disable-next-line @typescript-eslint/no-inferrable-types
import { useEffect, useState } from 'react';

/**
 * retorna el ultimo valor emitido de acuerdo a un timer
 * @param input valor
 * @param time tiempo de retardo
 * @returns ultimo valor emitido
 */
export const useDebouncedValue = (input: any = '', time = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(input);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(input);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [input, time]);

  return debouncedValue;
};
