
export const logout2 = ({fill, stroke, className}) => (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/logout">
        <g id="logout">
        <path id="Vector" d="M11.6267 10.2466C11.5 10.2466 11.3734 10.2 11.2734 10.1C11.08 9.90663 11.08 9.58663 11.2734 9.39329L12.6267 8.03996L11.2734 6.68663C11.08 6.49329 11.08 6.17329 11.2734 5.97996C11.4667 5.78663 11.7867 5.78663 11.98 5.97996L13.6867 7.68663C13.88 7.87996 13.88 8.19996 13.6867 8.39329L11.98 10.1C11.88 10.2 11.7534 10.2466 11.6267 10.2466Z" fill={fill}/>
        <path id="Vector_2" d="M13.2867 8.54004H6.50665C6.23332 8.54004 6.00665 8.31337 6.00665 8.04004C6.00665 7.76671 6.23332 7.54004 6.50665 7.54004H13.2867C13.56 7.54004 13.7867 7.76671 13.7867 8.04004C13.7867 8.31337 13.56 8.54004 13.2867 8.54004Z" fill={fill}/>
        <path id="Vector_3" d="M7.83999 13.8332C4.40665 13.8332 2.00665 11.4332 2.00665 7.99984C2.00665 4.5665 4.40665 2.1665 7.83999 2.1665C8.11332 2.1665 8.33999 2.39317 8.33999 2.6665C8.33999 2.93984 8.11332 3.1665 7.83999 3.1665C4.99332 3.1665 3.00665 5.15317 3.00665 7.99984C3.00665 10.8465 4.99332 12.8332 7.83999 12.8332C8.11332 12.8332 8.33999 13.0598 8.33999 13.3332C8.33999 13.6065 8.11332 13.8332 7.83999 13.8332Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
    