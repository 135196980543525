
export const close = ({fill, stroke, className}) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/close-circle">
        <g id="close-circle">
        <path id="Vector" d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M9.16998 14.8299L14.83 9.16992M14.83 14.8299L9.16998 9.16992" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    