import { useDispatch } from 'react-redux';
import { setNotification } from '../redux/slices/notificationSlice';
import { NotificationType } from '../interfaces';

/**
 * Custom hook para la configuracion del modal de notificacion
 */
export const useNotification = () => {

    const dispatch = useDispatch();

    /**
     * Asigna los valores para mostrar una notificación
     */
    const showNotification = (type: NotificationType, title: string, message: string) => {
        dispatch(setNotification({
            type,
            title,
            message,
            isVisible: true,
        }));
    };

    /**
     * Oculta el modal de notificacion
     */
    const hiddeNotification = () => {
        dispatch(setNotification({
            type: 'success',
            title: '',
            message: '',
            isVisible: false,
        }));
    };

    return { showNotification, hiddeNotification };
};
