
export const cards = ({fill, stroke, className}) => (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/cards">
        <path id="Vector" d="M3.08301 16.7891H25.0413" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M25.0413 13.7789V23.0143C25.0026 26.6955 23.9951 27.6255 20.1588 27.6255H7.96555C4.06471 27.6255 3.08301 26.6568 3.08301 22.8076V13.7789C3.08301 10.2914 3.89676 9.1676 6.95801 8.98677C7.26801 8.97385 7.60388 8.96094 7.96555 8.96094H20.1588C24.0596 8.96094 25.0413 9.92969 25.0413 13.7789Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M28.9163 9.19292V18.2217C28.9163 21.7092 28.1026 22.8329 25.0413 23.0137V13.7783C25.0413 9.92916 24.0596 8.96042 20.1588 8.96042H7.96555C7.60388 8.96042 7.26801 8.97333 6.95801 8.98625C6.99676 5.305 8.0043 4.375 11.8405 4.375H24.0338C27.9346 4.375 28.9163 5.34375 28.9163 9.19292Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M7.28125 23.5039H9.50288" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M12.2666 23.5039H16.7099" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
)