import { useEffect, useState } from "react";
import "./Autocomplete.scss";
import { Autocomplete, createFilterOptions } from "@mui/material";
export interface AutocompleteOption {
  value: string;
  label: string;
  id: string;
  [key: string]: any;
}

interface SelectorFormProps {
  label: string;
  options: AutocompleteOption[];
  onSelect: Function;
  value?: AutocompleteOption;
  disabled?: boolean;
  multiple?: boolean;
  allowNew?: boolean;
  renderOption?: any;
}

const filter = createFilterOptions<AutocompleteOption>();

/**
 * Componente Selector para formularios
 * @param {string} label Titulo del selector
 * @param {array} options Lista de opciones del selector
 * @param {function} onSelect Funcion que regresa el valor seleccionado del selector
 */
export const AutocompleteForm = ({
  label,
  options,
  onSelect,
  value,
  disabled,
  multiple,
  allowNew,
  renderOption
}: SelectorFormProps) => {
  const [currentValue, setCurrentValue] = useState<AutocompleteOption>();

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  return (
    <div className="flex h-[82px] flex-col items-start gap-[10px] w-full autocomplete-form">
      <label className="text-[12.8px] text-neutral-7 font-SoraRegular tracking-wide leading-normal ml-2">{label}</label>
      <Autocomplete
        fullWidth
        multiple={multiple}
        options={options}
        disabled={disabled}
        value={currentValue || null}
        onChange={(_, newValue) => {
          onSelect(newValue);
          if (newValue) setCurrentValue(newValue as AutocompleteOption);
        }}
        renderInput={({ id, disabled, inputProps, InputProps }) => (
          <div ref={InputProps.ref}>
            <input id={id} disabled={disabled} {...inputProps} />
          </div>
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting && allowNew) {
            const newValue = {
              id: '',
              value: inputValue,
              label: inputValue,
            }
            filtered.push(newValue);
          }
  
          return filtered;
        }}
        renderOption={renderOption}
      />
    </div>
  );
};
