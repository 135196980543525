
export const movementsinactive = ({fill, stroke, className}) => (
    <svg className={className} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/activity">
        <g id="activity">
        <path id="Vector" d="M10.6253 16.6139H6.37533C2.52908 16.6139 0.885742 14.9706 0.885742 11.1243V6.87435C0.885742 3.0281 2.52908 1.38477 6.37533 1.38477H10.6253C14.4716 1.38477 16.1149 3.0281 16.1149 6.87435V11.1243C16.1149 14.9706 14.4716 16.6139 10.6253 16.6139ZM6.37533 2.44727C3.10991 2.44727 1.94824 3.60893 1.94824 6.87435V11.1243C1.94824 14.3898 3.10991 15.5514 6.37533 15.5514H10.6253C13.8907 15.5514 15.0524 14.3898 15.0524 11.1243V6.87435C15.0524 3.60893 13.8907 2.44727 10.6253 2.44727H6.37533Z" fill={fill}/>
        <path id="Vector_2" d="M5.19224 11.2957C5.07891 11.2957 4.96558 11.2603 4.86641 11.1824C4.63266 11.0053 4.59016 10.6724 4.76724 10.4387L6.45308 8.2499C6.65849 7.98782 6.94891 7.81782 7.28183 7.77532C7.60766 7.73282 7.94058 7.8249 8.20266 8.03032L9.49891 9.05032C9.54849 9.09282 9.59808 9.09282 9.63349 9.08574C9.66183 9.08574 9.71141 9.07157 9.75391 9.0149L11.3902 6.90407C11.5672 6.67032 11.9072 6.62782 12.1339 6.81199C12.3677 6.98907 12.4102 7.32199 12.226 7.55574L10.5897 9.66657C10.3843 9.92865 10.0939 10.0987 9.76099 10.1341C9.42808 10.1766 9.10224 10.0845 8.84016 9.87907L7.54391 8.85907C7.49433 8.81657 7.43766 8.81657 7.40933 8.82365C7.38099 8.82365 7.33141 8.83782 7.28891 8.89449L5.60308 11.0832C5.51099 11.2249 5.35516 11.2957 5.19224 11.2957Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
    