import ModalView from 'react-modal';
import { useNotification } from '../../hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import './Notification.scss';

const customStyles = {
    overlay: {
        backgroundColor: '#000F0880',
        display: 'flex',
        alignContent: 'center',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: 'auto',
        padding: '24px',
        transform: 'translate(-50%, -50%)',
        borderRadius: '16px',
    },
};

/**
 * Muestra una notificación genérica al usuario
 */
export const Notification = () => {

    const { isVisible, type, title, message } = useSelector((state: RootState) => state.notification);
    const { hiddeNotification } = useNotification();

    /**
     * Actualiza el redux para cerrar el modal y reestablecer los valores
     */
    const closeModal = () => {
        hiddeNotification();
    };

    return (
        <ModalView isOpen={isVisible} style={customStyles} ariaHideApp={false}>
            <div className='notification-container flex flex-col h-[354px] w-[580px]'>
                <div data-testid="close-noti" onClick={() => closeModal()} className='flex flex-row-reverse items-start h-[53px]'>
                    <img src={`/img/icons/close.svg`} alt='close' />
                </div>
                <div className={`content ${type}`}>
                    <div className={`icon ${type}`}>
                        <img src={`/img/icons/${type}.svg`} alt='type' />
                    </div>
                    <h3 className='text-neutral-8 font-SoraBold leading-normal mb-3'>{title}</h3>
                    <p className='text-neutral-5 font-ArialRegular mb-6'>{message}</p>
                </div>
                <button className={`primary ${type}`} onClick={() => closeModal()}>Continuar</button>
            </div>
        </ModalView>
    );
};
