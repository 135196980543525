
type CheckProps = {
    label: string,
    onCheck: Function,
};

export const CheckForm = ({ label, onCheck }: CheckProps) => {
    return(
        <div className='flex items-center gap-2 py-4 px-3 box-border flex-nowrap'>
            <input className='w-[17px] h-[17px] border border-neutral-3 rounded-md accent-accent' type='checkbox' id='input-check' onChange={e => onCheck(e.target.checked)}/>
            <p className='text-neutral-7 font-ArialRegular text-[12.8px] leading-normal tracking-[0.5px] mt-1 text-start'>{label}</p>
        </div>
    );
};
