
export const chart = ({fill, stroke, className}) => (
    <svg className={className} width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/chart-2">
        <g id="chart-2">
        <path id="Vector" d="M16.2503 25.1471H9.75033C3.86783 25.1471 1.35449 22.6338 1.35449 16.7513V10.2513C1.35449 4.3688 3.86783 1.85547 9.75033 1.85547H16.2503C22.1328 1.85547 24.6462 4.3688 24.6462 10.2513V16.7513C24.6462 22.6338 22.1328 25.1471 16.2503 25.1471ZM9.75033 3.48047C4.75616 3.48047 2.97949 5.25714 2.97949 10.2513V16.7513C2.97949 21.7455 4.75616 23.5221 9.75033 23.5221H16.2503C21.2445 23.5221 23.0212 21.7455 23.0212 16.7513V10.2513C23.0212 5.25714 21.2445 3.48047 16.2503 3.48047H9.75033Z" fill={fill}/>
        <path id="Vector_2" d="M16.7917 21.3529C15.145 21.3529 13.8125 20.0204 13.8125 18.3737V8.6237C13.8125 6.97703 15.145 5.64453 16.7917 5.64453C18.4383 5.64453 19.7708 6.97703 19.7708 8.6237V18.3737C19.7708 20.0204 18.4383 21.3529 16.7917 21.3529ZM16.7917 7.26953C16.0442 7.26953 15.4375 7.8762 15.4375 8.6237V18.3737C15.4375 19.1212 16.0442 19.7279 16.7917 19.7279C17.5392 19.7279 18.1458 19.1212 18.1458 18.3737V8.6237C18.1458 7.8762 17.5392 7.26953 16.7917 7.26953Z" fill={fill}/>
        <path id="Vector_3" d="M9.20866 21.3555C7.56199 21.3555 6.22949 20.023 6.22949 18.3763V14.5846C6.22949 12.938 7.56199 11.6055 9.20866 11.6055C10.8553 11.6055 12.1878 12.938 12.1878 14.5846V18.3763C12.1878 20.023 10.8553 21.3555 9.20866 21.3555ZM9.20866 13.2305C8.46116 13.2305 7.85449 13.8371 7.85449 14.5846V18.3763C7.85449 19.1238 8.46116 19.7305 9.20866 19.7305C9.95616 19.7305 10.5628 19.1238 10.5628 18.3763V14.5846C10.5628 13.8371 9.95616 13.2305 9.20866 13.2305Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
    