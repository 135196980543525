import { AccountsService } from '../service/accounts/accountsService';
import { store } from "../redux/store";
import { updateCurrentCustomerBalance } from '../redux/slices/profileSlices';

type CheckItem = {
    key: string,
    checked: boolean,
}

enum currencyValues {
    USD = 'USD',
    MXN = 'MXN',
    EUR = 'EUR',
    GBP = 'GBP',
    JPY = 'JPY',
    CAD = 'CAD',
    AUD = 'AUD',
    CNY = 'CNY',
    INR = 'INR',
    BRL = 'BRL',
    CLP = 'CLP',
    ARS = 'ARS',
}

const currencyToLocale = {
    [currencyValues.USD]: 'en-US',
    [currencyValues.MXN]: 'es-MX',
    [currencyValues.EUR]: 'es-ES',
    [currencyValues.GBP]: 'en-GB',
    [currencyValues.JPY]: 'ja-JP',
    [currencyValues.CAD]: 'en-CA',
    [currencyValues.AUD]: 'en-AU',
    [currencyValues.CNY]: 'zh-CN',
    [currencyValues.INR]: 'hi-IN',
    [currencyValues.BRL]: 'pt-BR',
    [currencyValues.CLP]: 'es-CL',
    [currencyValues.ARS]: 'es-AR',
};

export const getListOfChecks = (object: any) => {
    const list: CheckItem[] = [];
    Object.entries(object).forEach(([key, _]) => {
        list.push({ key, checked: false });
    });

    return list;
};

export const formatDate = (data: string): string => {
    if(!data) return '-';

    const utcDate = new Date(data.replace(/[zZ]/, ''));

    const year = utcDate.getFullYear();
    const month = String(utcDate.getMonth() + 1).padStart(2, "0");
    const day = String(utcDate.getDate()).padStart(2, "0");
    const hours = String(utcDate.getHours()).padStart(2, "0");
    const minutes = String(utcDate.getMinutes()).padStart(2, "0");

    const localFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return localFormattedDate;
};

export const formatDateWhitDay = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const format = date.toLocaleDateString('es-ES', options)
    .replace(/^\w/, (c) => c.toUpperCase())
    .replace(/,/, '');

    return format;
};

export const formatCurrency = (n?: number, type = 'MXN') => {
    const currency = currencyValues[type as currencyValues] || 'MXN'
    const locale = currencyToLocale[currency];
    const formattedValue = (n || 0).toLocaleString(locale, {
        style: 'currency',
        currency: currency
      });

    return `${formattedValue} ${type}`;
}
  
export const filterObjectAndChildByKeys = (data: any[], childs: string, valueSearch: string, searchKeys: string[], searchChildKeys: string[]): any[] => {
    const filter = data.map( (item: any) => {

        const matchesSearch = searchKeys.some((key) => {
            const value = item[key]?.toString();
            return value?.toLowerCase().includes(valueSearch);
        });

        if (matchesSearch) {
            return item;
        }

        const itemChild = item[childs] ?? [];
  
        if(itemChild.length > 0){
            const nestedFilter = itemChild.filter((itemNested: any) =>
                searchChildKeys.some((key) => {
                    const value = itemNested[key].toString();
                    return value?.toLowerCase().includes(valueSearch);
                })
            );
  
            if(nestedFilter.length > 0) {
                return {
                    ...item,
                    [childs]: nestedFilter
                }
            }
        }
      }).filter(item => item !== null && item !== undefined);

    return filter;
}


export const getName = (account: any): string => {
    if(account.taxpayer_type_id === 2) return (account?.company_name || account?.contact_name || "")?.toUpperCase?.()
        const fullName = `${account?.name || ''} ${account?.ap_paterno || ''} ${account?.ap_materno || ''}`.trim()
        return  (fullName || account?.company_name || account?.contact_name || "")?.toUpperCase?.()
}

export const getTransactionError = (code: string = 'CODE-000'): string => {
    const errorCatalog: {[key: string]: string} = {
      'CODE-000': "Por alguna razón no se concreto la operación te recomendamos intentarlo de nuevo.",
      'CODE-001': 'La cuenta de origen no existe.',
      'CODE-002': 'No es posible generar la transacción con la cuenta de origen seleccionada.',
      'CODE-003': 'No cuentas con fondos suficientes para completar la transacción.',
    }
    return errorCatalog[code] || errorCatalog['CODE-000'];
  }

export const updateBalance = async (accountId: string) =>{
    const response = await AccountsService.getAccount(accountId);
    if(response.amount){
        store.dispatch(updateCurrentCustomerBalance(response.amount))
    }

}