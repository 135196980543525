import {ItemSidenav} from './components';
import './Sidenav.scss';
import { APP_NAME, APP_TEXTS, CUSTOM_SIDENAV_STYLE } from '../../utils/constants';
import { Menus } from './model';
import { Modal } from '../modal';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { captureFeedback } from '@sentry/react';
import { Button } from '../button';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getName } from '../../utils/functions';
import { useNotification } from '../../hooks';
const {REACT_APP_SENTRY_DNS} = process.env;

export const Sidenav = ({ options, onSelect }: {options: Menus, onSelect: Function}) => {
    const [openBugModal, setOpenBugModal] = useState(false)
    const [renderSentry, setRenderSentry] = useState(false);
    const [bugDescription, setBugDescription] = useState('');
    const {information} = useSelector((state: RootState) => state.user);
    const { showNotification } = useNotification();

    useEffect(() => {
        const {REACT_APP_SENTRY_DNS} = process.env;
        setRenderSentry(REACT_APP_SENTRY_DNS !== undefined);
    }, []);
    
    const onClickSendBugReport = ()=> {
        
        const eventID =captureFeedback({
            name: getName(information), // optional
            email: information.contact_email, // optional
            message: bugDescription, // required
        },{
            includeReplay:true
        });

        setBugDescription('');
        setOpenBugModal(false);
        showNotification(
            'success',
            'Hemos recibido tu reporte',
            `Tu número de seguimiento es: ${eventID}`
        );

    }
    return (<div className={`sidenav-component hidden lg:block ${CUSTOM_SIDENAV_STYLE?.classes?.join(' ')} h-full flex min-h-[580px] z-10`}>
        <img src={`${CUSTOM_SIDENAV_STYLE?.menuSmImg || `/img/logo/${APP_NAME}.svg`}`} alt={APP_TEXTS?.displayName} className={`block lg:hidden py-4 w-full ${CUSTOM_SIDENAV_STYLE?.menuSmImgClass || ''}`}/>
        {
            options.map((option, index) => <ItemSidenav
            key={`${option.name}-${index}`}
                name={option.name}
                icon={option.icon}
                suboptions={option.suboptions? option.suboptions : []}
                menu={option.menu? option.menu : 0}
                onSelect={(e: number) => onSelect(e)}
            />)
        }
       { CUSTOM_SIDENAV_STYLE?.img && <div className='self-end mt-auto relative h-full w-full'><img src={CUSTOM_SIDENAV_STYLE?.img} alt={APP_TEXTS?.displayName} className='absolute right-0 bottom-0 img-sidemenu lg:w-full w-32 mr-2 lg:mr-0'/></div>}
       { renderSentry && <div className='absolute bottom-5'> 
            <button data-testid='feeback-button' id='feeback-button' className='flex h-[62px] w-[231px] justify-center items-center gap-[10px] bg-header bg-opacity-1 text-neutral-5 rounded-md border border-dotted border-neutral-3' onClick={()=> setOpenBugModal(true)}>
                <img src='/img/icons/message-question.svg' alt='faq'/>
                <p className='text-[12px] font-SoraSemiBold leading-[15.12px]'>Reportar un problema</p>
            </button>
        </div> }
       <Modal title='Reportar un problema' open={openBugModal} onClose={()=>{setOpenBugModal(false); setBugDescription('')}}>
        <div className='w-[60vw] max-w-[630px] flex flex-col gap-4 p-3'>
            <TextField label='Describa el problema presentado' value={bugDescription} onChange={(e)=>setBugDescription(e.target.value)} rows={5} multiline />
            <Button label='Enviar Reporte' onClick={onClickSendBugReport} disabled={!bugDescription}  type='primary'/>
        </div>
       </Modal>
    </div>);
};
