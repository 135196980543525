import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cleanSession } from '../../redux/slices/profileSlices';
import { PATHS } from '../../utils/constants';
import { useSpinner } from '../../hooks';
import { AuthService } from '../../service';
import './ProfileMenu.scss';

interface ProfileMenuProps {
    name: string,
    email: string,
    createOTP: Function
};

/**
 * Menu que se muestra cuando se selecciona el nombre del cliente
 * Da acceso a editar la información personal, cambio de contraseña y cerrar sesión
 * @param {string} name Nombre del cliente, se obtiene del api de customers
 * @param {string} email Correo electronico del cliente, se obtiene del api de customers
 */
export const ProfileMenu = ({ name, email, createOTP }: ProfileMenuProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingSpinner] = useSpinner();

    const logout = async() => {
        loadingSpinner({loading: true});
        await AuthService.logout();
        loadingSpinner({loading: false});
        dispatch(cleanSession());                
        navigate(PATHS.login);
    }

    return(
        <div className='profile-menu-container flex flex-col w-[182px]'>
            <div className='menu border-b'>
                <img src={'/img/icons/edit-profile.svg'} alt='edit'/>
                <div className='info'>
                    <p>Hola, {name}</p>
                    <p className='profile-email'>{email}</p>
                </div>
            </div>
            <div className='menu border-b'>
                <img src='/img/icons/security.svg' alt='security'/>
                <p>Cambiar contraseña</p>
            </div>
            <div data-testid="option-otp" className='menu border-b' onClick={() => createOTP()}>
                <img src='/img/icons/scan-barcode.svg' alt='scan'/>
                <p>Genera tu código OTP</p>
            </div>
            <div data-testid="option-logout" className='menu' onClick={() => logout()}>
                <img src='/img/icons/logout-2.svg' alt='logout'/>
                <p>Cerrar sesión</p>
            </div>
        </div>
    );
};
