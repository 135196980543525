
export const documentText = ({fill, stroke, className}) => (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/document-text">
        <g id="document-text">
        <path id="Vector" d="M21.5 7.5V17.5C21.5 20.5 20 22.5 16.5 22.5H8.5C5 22.5 3.5 20.5 3.5 17.5V7.5C3.5 4.5 5 2.5 8.5 2.5H16.5C20 2.5 21.5 4.5 21.5 7.5Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M15 5V7C15 8.1 15.9 9 17 9H19" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M8.5 13.5H12.5" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M8.5 17.5H16.5" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    