
export const more = ({fill, stroke, className}) => (
    <svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/more">
        <g id="more">
        <path id="Vector" d="M3.33333 7.16699C2.6 7.16699 2 7.76699 2 8.50033C2 9.23366 2.6 9.83366 3.33333 9.83366C4.06667 9.83366 4.66667 9.23366 4.66667 8.50033C4.66667 7.76699 4.06667 7.16699 3.33333 7.16699Z" stroke={stroke} strokeWidth="1.5"/>
        <path id="Vector_2" d="M12.6667 7.16699C11.9334 7.16699 11.3334 7.76699 11.3334 8.50033C11.3334 9.23366 11.9334 9.83366 12.6667 9.83366C13.4 9.83366 14 9.23366 14 8.50033C14 7.76699 13.4 7.16699 12.6667 7.16699Z" stroke={stroke} strokeWidth="1.5"/>
        <path id="Vector_3" d="M7.99996 7.16699C7.26663 7.16699 6.66663 7.76699 6.66663 8.50033C6.66663 9.23366 7.26663 9.83366 7.99996 9.83366C8.73329 9.83366 9.33329 9.23366 9.33329 8.50033C9.33329 7.76699 8.73329 7.16699 7.99996 7.16699Z" stroke={stroke} strokeWidth="1.5"/>
        </g>
        </g>
    </svg>
)