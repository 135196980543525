import { DataTableColumn } from '../../model';

interface RowProperties {
    colums: DataTableColumn[];
    index: number;
    rowCheckCallback?: (
        isSelected: boolean,
        data: { [key: string]: string | boolean | number }
      ) => void;
    row: any
    isParentRow?: boolean;
    isChildRow?: boolean;
    rowClass?: string;
    isChecked?: boolean
}

export const Row = ({colums, index:rowIndex,rowCheckCallback, row, isChildRow, isParentRow, rowClass, isChecked = false}: RowProperties) => {

    return  (
        <tr key={`table-body-row-${rowIndex + 1}`} className={rowClass ?? 'h-14'}>
          {colums.map(
            (
              {
                size,
                index,
                altIndex,
                checkbox,
                CustomComponent,
                style,
                align,
                valueFormatter,
                isParent,
                isChild
              },
              i
            ) => {
              let value = ''
              try {
                // eslint-disable-next-line no-eval
                value = eval('row.'+index)
                if(!value) value = eval('row.'+altIndex)
              } catch (error) {
                try {
                  value = eval('row.'+altIndex)
                } catch (error) {
                  value = '-'
                }
                
              }


              return(
              <td
                key={`table-row-${index}-${i + 1}`}
                className={`flex-${size ?? 1} text-ellipsis text-${align ?? 'left'}`}
                style={style}
              >
                {checkbox && (
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) =>
                      rowCheckCallback?.(Boolean(e.target.checked), row)
                    }
                  />
                )}
                {CustomComponent && <CustomComponent {...row} value={value} index={rowIndex} isParent={isParentRow} isChild={isChildRow} />}
                {!CustomComponent &&
                  (valueFormatter
                    ? valueFormatter(value, row)
                    : value)}
              </td>
            )}
          )}
        </tr>
        
      )
}