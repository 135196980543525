
export const walletinactive = ({fill, stroke, className}) => (
    <svg className={className} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/wallet-add">
        <g id="wallet-add">
        <path id="Vector" d="M10.2712 11.9688H6.72949C6.43908 11.9688 6.19824 11.7279 6.19824 11.4375C6.19824 11.1471 6.43908 10.9062 6.72949 10.9062H10.2712C10.5616 10.9062 10.8024 11.1471 10.8024 11.4375C10.8024 11.7279 10.5616 11.9688 10.2712 11.9688Z" fill={fill}/>
        <path id="Vector_2" d="M8.5 13.7819C8.20958 13.7819 7.96875 13.5411 7.96875 13.2507V9.70898C7.96875 9.41857 8.20958 9.17773 8.5 9.17773C8.79042 9.17773 9.03125 9.41857 9.03125 9.70898V13.2507C9.03125 13.5411 8.79042 13.7819 8.5 13.7819Z" fill={fill}/>
        <path id="Vector_3" d="M3.68352 7.90843C3.54894 7.90843 3.41436 7.85176 3.30811 7.7526C3.15936 7.60385 3.10977 7.3701 3.19477 7.17176L4.51227 4.02676C4.54061 3.96301 4.55477 3.92051 4.57602 3.8851C5.62436 1.46968 6.96311 0.881762 9.32894 1.78843C9.46353 1.83801 9.56978 1.94426 9.62644 2.07885C9.68311 2.21343 9.68311 2.36218 9.62644 2.49676L7.55102 7.31343C7.46602 7.50468 7.27478 7.63218 7.06228 7.63218H5.04352C4.63977 7.63218 4.25727 7.7101 3.88894 7.86593C3.82519 7.89426 3.75436 7.90843 3.68352 7.90843ZM7.51561 2.44718C6.63727 2.44718 6.09894 3.02093 5.53936 4.32426C5.53227 4.34551 5.51811 4.36676 5.51102 4.38801L4.58311 6.59093C4.73894 6.57676 4.88769 6.56968 5.04352 6.56968H6.70811L8.41519 2.60301C8.08228 2.49676 7.78477 2.44718 7.51561 2.44718Z" fill={fill}/>
        <path id="Vector_4" d="M12.9555 7.77438C12.9059 7.77438 12.8492 7.76729 12.7997 7.75313C12.5376 7.67521 12.2472 7.63271 11.9497 7.63271H7.06216C6.88508 7.63271 6.71508 7.54063 6.61591 7.39188C6.52383 7.24313 6.50258 7.05188 6.57341 6.88896L8.62758 2.12188C8.73383 1.86688 9.0455 1.69688 9.30758 1.78188C9.39258 1.81021 9.4705 1.84563 9.5555 1.88104L11.2272 2.58229C12.2047 2.98604 12.8563 3.41104 13.2813 3.92104C13.3663 4.02021 13.4372 4.12646 13.5009 4.23271C13.5788 4.35313 13.6497 4.50188 13.6992 4.65063C13.7205 4.70021 13.7559 4.79229 13.7772 4.89146C13.9755 5.55729 13.8763 6.38604 13.4513 7.44854C13.3663 7.64688 13.168 7.77438 12.9555 7.77438ZM7.86966 6.57021H11.9567C12.1834 6.57021 12.403 6.59146 12.6226 6.62688C12.8209 6.01063 12.8634 5.53604 12.7501 5.15354C12.7359 5.08979 12.7217 5.06146 12.7147 5.03313C12.6722 4.91979 12.6438 4.85604 12.6084 4.79938C12.5588 4.72146 12.5234 4.65771 12.4667 4.59396C12.1622 4.22563 11.6238 3.88563 10.8234 3.55979L9.42091 2.97188L7.86966 6.57021Z" fill={fill}/>
        <path id="Vector_5" d="M11.2625 16.6145H5.7375C5.53917 16.6145 5.355 16.6003 5.17083 16.5791C2.68458 16.4091 1.26792 14.9924 1.09792 12.4778C1.07667 12.322 1.0625 12.1307 1.0625 11.9395V10.5582C1.0625 8.96448 2.01167 7.52656 3.47792 6.88906C3.97375 6.67656 4.505 6.57031 5.05042 6.57031H11.9637C12.3675 6.57031 12.7571 6.62698 13.1183 6.74031C14.7829 7.24323 15.9517 8.81573 15.9517 10.5582V11.9395C15.9517 12.0953 15.9446 12.2441 15.9375 12.3857C15.7817 15.1553 14.1667 16.6145 11.2625 16.6145ZM5.04333 7.63281C4.63958 7.63281 4.25708 7.71073 3.88875 7.86656C2.81208 8.33406 2.11792 9.38948 2.11792 10.5582V11.9395C2.11792 12.0882 2.13208 12.237 2.14625 12.3786C2.28083 14.3974 3.2725 15.3891 5.26292 15.5236C5.44 15.5449 5.58167 15.5591 5.73042 15.5591H11.2554C13.5929 15.5591 14.7404 14.532 14.8537 12.3361C14.8608 12.2086 14.8679 12.0811 14.8679 11.9395V10.5582C14.8679 9.27615 14.0108 8.12865 12.7925 7.75323C12.5304 7.67531 12.24 7.63281 11.9425 7.63281H5.04333V7.63281Z" fill={fill}/>
        <path id="Vector_6" d="M1.58691 11.0892C1.2965 11.0892 1.05566 10.8484 1.05566 10.5579V8.48253C1.05566 6.25128 2.63525 4.32461 4.81691 3.89961C5.00816 3.8642 5.2065 3.93503 5.334 4.08378C5.45441 4.23253 5.48983 4.44503 5.41191 4.62211L4.17233 7.58295C4.11566 7.71045 4.0165 7.80961 3.89608 7.86628C2.81941 8.33378 2.12525 9.3892 2.12525 10.5579C2.11816 10.8484 1.88441 11.0892 1.58691 11.0892ZM3.96691 5.33045C3.06025 5.84045 2.4015 6.73295 2.19608 7.7742C2.50775 7.45545 2.869 7.18628 3.27983 6.98795L3.96691 5.33045Z" fill={fill}/>
        <path id="Vector_7" d="M15.4138 11.0894C15.1234 11.0894 14.8826 10.8486 14.8826 10.5582C14.8826 9.27609 14.0255 8.12859 12.8071 7.75318C12.6655 7.71068 12.5451 7.61151 12.4813 7.47693C12.4176 7.34234 12.4105 7.18651 12.4671 7.05193C12.8001 6.22318 12.8851 5.62109 12.7505 5.15359C12.7363 5.08984 12.7221 5.06151 12.7151 5.03318C12.623 4.82776 12.6726 4.58693 12.8355 4.43109C12.9984 4.27526 13.2463 4.23984 13.4446 4.34609C14.9888 5.15359 15.9451 6.74026 15.9451 8.48276V10.5582C15.9451 10.8486 15.7042 11.0894 15.4138 11.0894ZM13.6359 6.93859C14.0821 7.14401 14.4788 7.43443 14.8117 7.78151C14.6771 7.08734 14.3442 6.45693 13.8555 5.96109C13.8201 6.25859 13.7492 6.58443 13.6359 6.93859Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
    