
export const receipt = ({fill, stroke, className}) => (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/receipt">
        <g id="receipt">
        <path id="Vector" d="M9.19259 25.9445C10.2518 24.8079 11.8663 24.8983 12.7963 26.1383L14.1009 27.882C15.1472 29.2641 16.8393 29.2641 17.8855 27.882L19.1901 26.1383C20.1201 24.8983 21.7347 24.8079 22.7938 25.9445C25.093 28.3987 26.9659 27.5849 26.9659 24.1491V9.59203C26.9788 4.38661 25.7647 3.08203 20.8822 3.08203H11.1172C6.23467 3.08203 5.02051 4.38661 5.02051 9.59203V24.1362C5.02051 27.5849 6.90634 28.3858 9.19259 25.9445Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M10.833 9.54297H21.1663" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M12.125 14.707H19.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    