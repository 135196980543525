import {OptionCard} from '../option-card';
import { MARKETPLACE_OPTIONS } from '../../utils/constants'; 

/**
 * Muestra las opciones de servicios para la cuenta
 * @returns 
 */
export const MarketPlace = () => {
    return (
        <div className='bg-specialBG border-t '>
            <div className='border-b pb-5 pt-7 pl-6 text-left font-bold'>
                Próximamente
            </div>
            <div className='marketplace-container flex w-full flex-col justify-center lg:flex-row lg:justify-evenly gap-9 p-6'>
                {MARKETPLACE_OPTIONS.map((market, index) => <OptionCard disabled label={market.label} icon={market.icon} key={`${market.label}-${index}`}/>)}
            </div>
        </div>
    );
};