import { IAccountsInfo } from "../../interfaces/accountsInterface";
import { ICustomer, CustomersPagedResponse, IFavorites } from "../../interfaces/customerInterface";
import { URL_API } from "../../utils/constants";
import { ConsumeService } from "../ConsumeService";

export const CustomersServices = {
    async getCustomerById (id: string): Promise<ICustomer | null> {
        try {
            const url = `${URL_API.CUSTOMERS}/${id}`;
            const { data } = await ConsumeService.get(url);
            return data.data ?? null;
    
        } catch (error) {
            return null
        }
    },

    async getCustomersPaged (filter: string, limit: number, offset: number, partnerCustomerId: string): Promise<CustomersPagedResponse> {
        try {
            const url = `${URL_API.CUSTOMERS}/paged`;

            const { data } = await ConsumeService.get(url, {params: {
                filter,
                limit,
                offset,
                partnerCustomerId,
            } });
            
            return {
                status: "ok",
                code: 200,
                total: data.total,
                data: data.data,
            };
    
        } catch (error: any) {
            return {
                status:  error.response?.status || error.message || '',
                total: 0,
                code: 500,
                data: null,
            };
        }
    },

    /**
     * Obtiene las cuentas por cliente
     * @param {string} customerId Identificador del cliente
     */
    async getAccountsById (customerId: string): Promise<IAccountsInfo[] | null> {

        const url = `${URL_API.ACCOUNTS.CUSTOMERS}/${customerId}`
    
        try {
            const { data } = await ConsumeService.get(url);
            return data.data ?? null;
        } catch (error: any) {
            return null
        }
    },

    async getFavorites (customerId: string): Promise<IFavorites[]> {

        const url = `${URL_API.ACCOUNTS.CUSTOMERS}/${customerId}/favorites`
    
        try {
            const { data } = await ConsumeService.get(url);
            return data.data;
        } catch (error: any) {
            return []
        }
    },
}