
export const personalCard = ({fill, stroke, className}) => (
    <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/personalcard">
        <g id="personalcard">
        <path id="Vector" d="M17.5 21H7.5C3.5 21 2.5 20 2.5 16V8C2.5 4 3.5 3 7.5 3H17.5C21.5 3 22.5 4 22.5 8V16C22.5 20 21.5 21 17.5 21Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M14.5 8H19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M15.5 12H19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M17.5 16H19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M8.99994 11.29C9.99958 11.29 10.8099 10.4796 10.8099 9.48C10.8099 8.48036 9.99958 7.67 8.99994 7.67C8.00031 7.67 7.18994 8.48036 7.18994 9.48C7.18994 10.4796 8.00031 11.29 8.99994 11.29Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_6" d="M12.5 16.33C12.36 14.88 11.21 13.74 9.76 13.61C9.26 13.56 8.75 13.56 8.24 13.61C6.79 13.75 5.64 14.88 5.5 16.33" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    