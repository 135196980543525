import { useEffect, useState } from "react";
import classNames from "classnames";
import "./InputForm.scss";

type InputLoginProps = {
  type: "text" | "password" | "number" | "date";
  label?: string;
  placeholder: string;
  onTextChange: Function;
  maxLength?: number;
  min?: number;
  max?: number;
  inputOverrideClass?: string;
  disabled?: boolean;
  value?: string;
};

export const InputForm = ({
  label,
  placeholder,
  type,
  onTextChange,
  maxLength,
  min,
  max,
  inputOverrideClass,
  disabled,
  value: settedValue
}: InputLoginProps) => {
  const [valid, setValid] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const inputClass = classNames({ valid });

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const enforceMinMax = (el: any) => {
    const deleteCodes = ['Delete', 'Backspace']
    const blockedKeys =  ["e", "E", "+", "-"]
    if(blockedKeys.includes(el.key)){
      // Prevent the input value change
      //el.target.blur();
      el.preventDefault()
      return false
    }
    const value = Number(el?.target?.value);

    if(!(value <= (max || Number.MAX_VALUE)) && (value >= (min || Number.MIN_VALUE)) && !deleteCodes.includes(el.code)){
      // Prevent the input value change
      //el.target.blur();
      el.preventDefault()
      // Prevent the page/container scrolling
      el.stopPropagation();
      return false
    }
    return true
  }

  const onChange = (e: any) => {
    const inputValue = e.target.value;

    // Restringir la longitud
    if (maxLength !== undefined && inputValue.length > maxLength) {
      e.preventDefault();
      return;
    }

    if (type === "number") {

      if(enforceMinMax(e)){
        onTextChange(inputValue);
        setValid(inputValue.length > 0);
        setValue(inputValue);
      }
      return;
    }

    setValue(inputValue);
    onTextChange(inputValue);
    setValid(inputValue.length > 0);
  }

  useEffect(()=>{
    if(settedValue) setValue(settedValue.trim())
  },[settedValue])
  return (
    <div className={`input-form flex w-full h-[82px] gap-[10px] ${label ? 'flex-col items-start' : 'flex-row items-center'}`}>
      { label && <label className="text-neutral-7 font-SoraRegular text-[12.8px] leading-normal tracking-[0.5px] ml-2">
          {label}
        </label>
      }
      <input
        className={`${inputClass} ${inputOverrideClass || ''}`}
        type={type}
        onWheel={numberInputOnWheelPreventChange}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        {...((maxLength !== undefined && { maxLength }) || {})}
        onKeyDown={(e)=> {
          if(type == "number") enforceMinMax(e)
        }}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};
