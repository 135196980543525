import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LinearProgress from '@mui/material/LinearProgress';
import './Spinner.scss';

/**
 * Componente que bloquea la pantalla para indicar que se esta cargando la información
 */
export const Spinner = () => {

    /**
     * Para mostrar el componente es necesario de actualizar el estado del spinner
     */
    const spinner = useSelector((state: RootState) => state.spinner);

    return (<>
        {(spinner.loading || spinner.isLoadingAxios) &&
            <div className='spinner-container w-full'>
                <h1>Estamos procesando la información</h1>
                <div data-testid="linear-progress" className='linear-progress'>
                    <LinearProgress color="primary" />
                </div>
                <p>{spinner.text === '' ? 'Esto puede tardar un poco...' : spinner.text}</p>
            </div>
        }
    </>);
};
