
export const briefcase = ({fill, stroke, className}) => (
    <svg className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/briefcase">
        <g id="briefcase">
        <path id="Vector" d="M8.50007 22H16.5001C20.5201 22 21.2401 20.39 21.4501 18.43L22.2001 10.43C22.4701 7.99 21.7701 6 17.5001 6H7.50007C3.23007 6 2.53007 7.99 2.80007 10.43L3.55007 18.43C3.76007 20.39 4.48007 22 8.50007 22Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M8.5 6V5.2C8.5 3.43 8.5 2 11.7 2H13.3C16.5 2 16.5 3.43 16.5 5.2V6" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M14.5 13V14C14.5 14.01 14.5 14.01 14.5 14.02C14.5 15.11 14.49 16 12.5 16C10.52 16 10.5 15.12 10.5 14.03V13C10.5 12 10.5 12 11.5 12H13.5C14.5 12 14.5 12 14.5 13Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_4" d="M22.15 11C19.84 12.68 17.2 13.68 14.5 14.02" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_5" d="M3.12012 11.27C5.37012 12.81 7.91012 13.74 10.5001 14.03" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
    </svg>
)
    