export const addCustomer = ({fill, stroke, className}) => (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/outline/profile-add">
        <g id="profile-add">
        <path id="Vector" d="M20.0423 21.9375H15.709C15.2648 21.9375 14.8965 21.5692 14.8965 21.125C14.8965 20.6808 15.2648 20.3125 15.709 20.3125H20.0423C20.4865 20.3125 20.8548 20.6808 20.8548 21.125C20.8548 21.5692 20.4865 21.9375 20.0423 21.9375Z" fill={fill}/>
        <path id="Vector_2" d="M17.875 24.1029C17.4308 24.1029 17.0625 23.7345 17.0625 23.2904V18.957C17.0625 18.5129 17.4308 18.1445 17.875 18.1445C18.3192 18.1445 18.6875 18.5129 18.6875 18.957V23.2904C18.6875 23.7345 18.3192 24.1029 17.875 24.1029Z" fill={fill}/>
        <path id="Vector_3" d="M13.1729 12.5896C13.1404 12.5896 13.1188 12.5896 13.0863 12.5896C13.0321 12.5788 12.9563 12.5788 12.8913 12.5896C9.7496 12.4921 7.3771 10.0221 7.3771 6.97797C7.36627 5.48297 7.95127 4.07464 9.01293 3.01297C10.0746 1.9513 11.4829 1.35547 12.9888 1.35547C16.0871 1.35547 18.6113 3.87964 18.6113 6.97797C18.6113 10.0221 16.2388 12.4813 13.2054 12.5896C13.1946 12.5896 13.1838 12.5896 13.1729 12.5896ZM12.9888 2.98047C11.9163 2.98047 10.9196 3.40297 10.1613 4.15047C9.41377 4.9088 9.0021 5.90547 9.0021 6.96713C9.0021 9.1338 10.6921 10.8888 12.8479 10.9538C12.9129 10.943 13.0538 10.943 13.1946 10.9538C15.3288 10.8563 16.9863 9.11213 16.9863 6.96713C16.9863 4.7788 15.1879 2.98047 12.9888 2.98047Z" fill={fill}/>
        <path id="Vector_4" d="M12.989 24.439C10.779 24.439 8.68818 23.8648 7.10651 22.8032C5.60068 21.7957 4.77734 20.4198 4.77734 18.9357C4.77734 17.4515 5.61151 16.0865 7.10651 15.0898C10.3457 12.9232 15.6107 12.9232 18.8498 15.0898C19.2182 15.339 19.3265 15.8482 19.0773 16.2165C18.8282 16.5957 18.319 16.6932 17.9507 16.444C15.2532 14.6457 10.7032 14.6457 8.00568 16.444C6.96568 17.1373 6.40234 18.0148 6.40234 18.9357C6.40234 19.8565 6.96568 20.7557 8.00568 21.449C9.31651 22.3265 11.0823 22.8032 12.9782 22.8032C13.4223 22.8032 13.7907 23.1715 13.7907 23.6157C13.7907 24.0598 13.4332 24.439 12.989 24.439Z" fill={fill}/>
        </g>
        </g>
    </svg>
)
    